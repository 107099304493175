//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-128:_5724,_3344,_7480,_512,_1384,_7656,_1900,_8616;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-128')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-128', "_5724,_3344,_7480,_512,_1384,_7656,_1900,_8616");
        }
      }catch (ex) {
        console.error(ex);
      }